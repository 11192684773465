import React from 'react'
import { clsx } from 'clsx'
import BodyText from '@ancon/wildcat-ui/web/orderweb/BodyText'
import HeadingText from '@ancon/wildcat-ui/web/orderweb/HeadingText'
import Image from 'next/image'
import OrderCharacterConfused from '@ancon/wildcat-ui/shared/static/illustrations/order-character-confused.svg'

import styles from './EmptyPlaceholder.module.scss'

type EmptyPlaceholderProps = {
  title: string
  message: string
  className?: string
  footer?: React.ReactNode
}

export default function EmptyPlaceholder({
  title,
  message,
  footer,
  className,
}: EmptyPlaceholderProps) {
  return (
    <section className={clsx(styles.container, className)}>
      <Image
        src={OrderCharacterConfused}
        width={120}
        height={120}
        alt={title}
      />
      <HeadingText as="h2">{title}</HeadingText>
      <BodyText color="body-1">{message}</BodyText>

      {footer}
    </section>
  )
}
