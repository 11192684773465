import useTranslation from 'next-translate/useTranslation'
import SearchIcon from '@ancon/wildcat-ui/shared/icons/i-search.svg'
import BackIcon from '@ancon/wildcat-ui/shared/icons/arrow-back-long.svg'
import { useEffect, useState } from 'react'

import useAppDispatch from '../../../../store/hooks/useAppDispatch'
import useAppSelector from '../../../../store/hooks/useAppSelector'
import Modal from '../../../app/components/modal/Modal'
import {
  outletsFiltersSelector,
  outletsVisibleOutletSearchModalSelector,
} from '../../store/outletsSelectors'
import { outletsSetVisibleOutletSearchModal } from '../../store/outletsSlice'
import { OutletSearchModalType } from '../../types'
import Input from '../../../app/components/Input'
import useAppStore from '../../../../store/hooks/useAppStore'
import Button from '../../../app/components/Button'

import styles from './OutletSearchModal.module.scss'
import OutletSearchSuggestions, {
  OutletSearchContext,
} from './suggestions/OutletSearchSuggestions'

export default function OutletSearchModal() {
  const { t } = useTranslation('common')
  const dispatch = useAppDispatch()
  const store = useAppStore()
  const visibleModal = useAppSelector(outletsVisibleOutletSearchModalSelector)
  const [searchTerm, setSearchTerm] = useState('')

  function handleModalDismiss() {
    dispatch(outletsSetVisibleOutletSearchModal(OutletSearchModalType.None))
  }

  function handleClearSearch() {
    setSearchTerm('')
  }

  function handleSearchTermChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchTerm(e.target.value)
  }

  function getSearchContext() {
    switch (visibleModal) {
      case OutletSearchModalType.OutletSearch:
        return OutletSearchContext.Outlets
      case OutletSearchModalType.PlaceSearch:
        return OutletSearchContext.Places
      default:
        return OutletSearchContext.All
    }
  }

  useEffect(() => {
    switch (visibleModal) {
      case OutletSearchModalType.OutletSearch:
        {
          const outletsFilters = outletsFiltersSelector(store.getState())

          if (outletsFilters.searchTerm) {
            setSearchTerm(outletsFilters.searchTerm)
          }
        }
        break
      case OutletSearchModalType.PlaceSearch:
        {
          const outletsFilters = outletsFiltersSelector(store.getState())

          if (outletsFilters?.location?.visibleSearchTerm) {
            const { visibleSearchTerm } = outletsFilters.location
            setSearchTerm(visibleSearchTerm)
          }
        }
        break
      case OutletSearchModalType.All:
        break
      case OutletSearchModalType.None:
      default:
        setSearchTerm('')
    }
  }, [store, visibleModal])

  return (
    <Modal
      title={
        visibleModal === OutletSearchModalType.OutletSearch
          ? t('components.outletSearchModal.searchByRestaurant')
          : t('components.outletSearchModal.searchByLocation')
      }
      isOpen={visibleModal !== OutletSearchModalType.None}
      onClose={handleModalDismiss}
      mobileModalMode="full-screen"
      className={styles.modal}
    >
      <div className={styles.modalBody}>
        <div className={styles.inputWrapper}>
          <Button
            variant="secondary"
            className={styles.backButton}
            onClick={handleModalDismiss}
          >
            <BackIcon />
          </Button>
          <Input
            leftAccessory={<SearchIcon />}
            containerClassName={styles.searchInput}
            highlighted
            placeholder={t('components.outletSearchModal.search')}
            clearable={!!searchTerm}
            value={searchTerm}
            onChange={handleSearchTermChange}
            rightAccessoryOnClick={handleClearSearch}
            autoFocus
          />
        </div>

        <div className={styles.resultSection}>
          <OutletSearchSuggestions
            searchTerm={searchTerm}
            context={getSearchContext()}
            hideTitle={visibleModal !== OutletSearchModalType.All}
          />
        </div>
      </div>
    </Modal>
  )
}
