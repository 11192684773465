import {
  APIGetRequest,
  PaginationParams,
  PrepaidWallet,
  PrepaidWalletPaginatedResponse,
  PrepaidWalletTransactionPaginatedResponse,
  WalletNumberType,
} from '@ancon/wildcat-types'

import Request from '../../client/Request'
import endpoints from '../endpoints'
import { APIType } from '../../types'

type PrepaidWalletsAPI = {
  get: {
    list: APIGetRequest<PrepaidWalletPaginatedResponse, PaginationParams>
    transactions: APIGetRequest<
      PrepaidWalletTransactionPaginatedResponse,
      PaginationParams
    >
    details: APIGetRequest<
      { prepaidWallet: PrepaidWallet },
      {
        tenantId: string
        prepaidWalletId: string
        walletNumberType: WalletNumberType
      }
    >
    walletTransactions: APIGetRequest<
      PrepaidWalletTransactionPaginatedResponse,
      PaginationParams<{
        tenantId: string
        prepaidWalletId: string
      }>
    >
  }
}

const prepaidWallets: PrepaidWalletsAPI = {
  get: {
    list: new Request(endpoints.card.prepaidWallets.list, {
      apiType: APIType.Card,
    }).get,
    transactions: new Request(endpoints.card.prepaidWalletTransactions.list, {
      apiType: APIType.Card,
    }).get,
    details: new Request(
      endpoints.card.tenants.byId.prepaidWallets.byId.details,
      {
        apiType: APIType.Card,
      },
    ).get,
    walletTransactions: new Request(
      endpoints.card.tenants.byId.prepaidWallets.byId.transactions.list,
      {
        apiType: APIType.Card,
      },
    ).get,
  },
}

export default prepaidWallets
