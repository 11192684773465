import { createSelector } from '@reduxjs/toolkit'
import isAOTippingEnabledForOrderFormat from '@ancon/wildcat-utils/outlet/isAOTippingEnabledForOrderFormat'

import { RootState } from '../../../store/types'
import { CheckReducerState } from '../types'

import { checkDetailsEntityAdapter } from './checkEntityAdapters'

function checkSelector<K extends keyof CheckReducerState>(
  state: RootState,
  key: K,
) {
  return state.check[key]
}

// MARK: Check details

const checkDetailsAdapterSelectors =
  checkDetailsEntityAdapter.getSelectors<RootState>(
    state => state.check.details,
  )

export const checkDetailsByIdSelector = checkDetailsAdapterSelectors.selectById

export const checkCurrentCheckIdSelector = (state: RootState) =>
  checkSelector(state, 'currentCheckId')

export const checkCurrentCheckDetailsSelector = (state: RootState) => {
  const currentCheckId = checkCurrentCheckIdSelector(state)

  if (!currentCheckId) {
    return null
  }

  return checkDetailsByIdSelector(state, currentCheckId) || null
}

export const checkCurrentCheckOutletSelector = (state: RootState) =>
  checkSelector(state, 'currentCheckOutlet')

export const checkCurrentCheckOutletIdSelector = (
  state: RootState,
): string | null => {
  const outlet = checkCurrentCheckOutletSelector(state)

  return outlet?.id || null
}

// Outlet details

export const checkCurrentCheckOutletDetailsSelector = (state: RootState) =>
  checkSelector(state, 'currentCheckOutletDetails')

// MARK: Adyen

export const checkIsFetchingAdyenPaymentMethodsSelector = (state: RootState) =>
  checkSelector(state, 'isFetchingCheckAdyenPaymentMethods')

export const checkAdyenPaymentMethodsSelector = (state: RootState) =>
  checkSelector(state, 'checkAdyenPaymentMethods')

export const checkAdyenPaymentMethodsErrorSelector = (state: RootState) =>
  checkSelector(state, 'checkAdyenPaymentMethodsError')

export const checkAdyenPaymentAttemptCountSelector = (state: RootState) =>
  checkSelector(state, 'checkAdyenPaymentAttemptCount')

export const checkIsAdyenPaymentInProgressSelector = (state: RootState) =>
  checkSelector(state, 'isCheckAdyenPaymentInProgress')

export const checkIsCancellingAdyenPaymentSelector = (state: RootState) =>
  checkSelector(state, 'isCancellingCheckAdyenPayment')

// Certain amount for partial payments

export const checkCertainAmountSelector = (state: RootState) =>
  checkSelector(state, 'checkCertainAmount')

// MARK: Tipping

export const checkTippingStateSelector = (state: RootState) =>
  checkSelector(state, 'checkTip')

export const checkTipPercentageSelector = (state: RootState) =>
  checkTippingStateSelector(state).tipPercentage

export const checkTippingVisibleModalTypeSelector = (state: RootState) =>
  checkTippingStateSelector(state).visibleModalType

export const checkCurrentCheckTipAmountSelector = createSelector(
  [
    checkCurrentCheckDetailsSelector,
    checkTippingStateSelector,
    checkCertainAmountSelector,
  ],
  (check, tipState, certainAmount) => {
    const { tipPercentage, customTipAmount } = tipState

    if (check) {
      if (tipPercentage) {
        const amount = certainAmount ?? check.totalAmount.amount
        const tipAmount = (amount * tipPercentage) / 100
        return Math.round(tipAmount * 100) / 100
      }

      return customTipAmount
    }

    return null
  },
)

const checkIsSkipTipModalSelector = createSelector(
  checkTippingStateSelector,
  tipState => tipState.isSkipTipModal,
)

export const checkIsTippingAppliedSelector = createSelector(
  checkTippingStateSelector,
  tipState => !!tipState.customTipAmount || !!tipState.tipPercentage,
)

export const checkCurrentCheckRequestTipSelector = createSelector(
  [
    checkCurrentCheckDetailsSelector,
    checkCurrentCheckOutletDetailsSelector,
    checkIsSkipTipModalSelector,
  ],
  (check, outlet, isSkipTipModal) => {
    if (check && outlet && isSkipTipModal !== true) {
      // Check if AO tipping is enabled
      const isAOTippingEnabled = isAOTippingEnabledForOrderFormat(
        outlet,
        check.orderFormat,
      )

      return isAOTippingEnabled
    }

    return false
  },
)
