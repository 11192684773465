import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { WalletsReducerState } from '../types'

import { fetchPrepaidWallet } from './walletsThunks'

const initialWalletsState: WalletsReducerState = {
  currentWalletDetails: null,
  isWalletTopUpModalVisible: false,
  isWalletCheckoutWarningModalVisible: false,
}

const walletsSlice = createSlice({
  name: 'wallets',
  initialState: initialWalletsState,
  reducers: {
    setWalletTopUpModalVisible(
      state: WalletsReducerState,
      action: PayloadAction<boolean>,
    ) {
      state.isWalletTopUpModalVisible = action.payload
    },
    setWalletCheckoutWarningModalVisible(
      state: WalletsReducerState,
      action: PayloadAction<boolean>,
    ) {
      state.isWalletCheckoutWarningModalVisible = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchPrepaidWallet.fulfilled, (state, action) => {
      state.currentWalletDetails = action.payload
    })
  },
})

export const {
  setWalletTopUpModalVisible,
  setWalletCheckoutWarningModalVisible,
} = walletsSlice.actions

export default walletsSlice.reducer
