import {
  CardNumberType,
  PrepaidWallet,
  PrepaidWalletPaginatedResponse,
  PrepaidWalletTransactionPaginatedResponse,
} from '@ancon/wildcat-types'
import APIFilterBuilder from '@ancon/wildcat-utils/api/APIFilterBuilder'

import { clientContextCustomerIdSelector } from '../../clientContext/store/clientContextSelectors'
import api from '../../../api'
import createAppAsyncThunk from '../../../store/createAppAsyncThunk'
import getTenantIds from '../../app/utils/getTenantIds'
import serializeError from '../../../store/utils/serializeError'
import { WalletTransactionTypes } from '../constants'
import { fetchOutletDetails } from '../../outlet/store/outletThunks'

export const fetchPrepaidWallets = createAppAsyncThunk<
  PrepaidWalletPaginatedResponse,
  | {
      offset?: number
      limit?: number
      walletId?: string
    }
  | undefined
>(
  'wallets/fetchPrepaidWallets',
  async (args, { getState, rejectWithValue }) => {
    const { offset, limit, walletId } = args || {}

    const tenantIds = getTenantIds()
    const customerId = clientContextCustomerIdSelector(getState())

    const walletsFilter = new APIFilterBuilder()

    if (customerId) {
      walletsFilter.addProperty('customerId').in(customerId)
    }

    if (tenantIds?.length) {
      walletsFilter.addFilter(
        'tenantId',
        tenantIds.map(type => `in:${type}`),
      )
    }

    if (walletId) {
      walletsFilter.addProperty('id').in(walletId)
    }

    try {
      const response = await api.card.prepaidWallets.get.list({
        limit,
        offset: offset || 0,
        filter: walletsFilter.build(),
      })

      return response.data
    } catch (error) {
      return rejectWithValue(serializeError(error))
    }
  },
)

export const fetchPrepaidWalletsTransactions = createAppAsyncThunk<
  PrepaidWalletTransactionPaginatedResponse,
  {
    offset?: number
    limit?: number
  }
>(
  'wallets/fetchPrepaidWalletsTransactions',
  async ({ offset, limit }, { getState, rejectWithValue }) => {
    const tenantIds = getTenantIds()
    const customerId = clientContextCustomerIdSelector(getState())

    const transactionsFilter = new APIFilterBuilder()

    if (customerId) {
      transactionsFilter.addProperty('customerId').in(customerId)
    }

    if (tenantIds?.length) {
      transactionsFilter.addProperty('tenantId').in(tenantIds)
    }

    /**
     * Note: The endpoint is not supported filter in:0,1,2.
     * So, we need to use multiple filters as following.
     * filter[type]: [in:0,in:1,in:2] etc.
     */
    transactionsFilter.addFilter(
      'type',
      Object.values(WalletTransactionTypes).map(type => `in:${type}`),
    )

    try {
      const response = await api.card.prepaidWallets.get.transactions({
        limit,
        offset,
        order: 'timestamp:desc',
        filter: transactionsFilter.build(),
      })

      return response.data
    } catch (error) {
      return rejectWithValue(serializeError(error))
    }
  },
)

export const fetchPrepaidWallet = createAppAsyncThunk<
  PrepaidWallet | null,
  {
    walletId: string
  }
>(
  'wallets/fetchPrepaidWallet',
  async ({ walletId }, { dispatch, rejectWithValue }) => {
    try {
      const wallets = await dispatch(
        fetchPrepaidWallets({ walletId, offset: 0, limit: 1 }),
      ).unwrap()

      if (wallets.meta.count > 0) {
        const walletTenantId = wallets.items[0].tenantId

        const response = await api.card.prepaidWallets.get.details({
          tenantId: walletTenantId,
          prepaidWalletId: walletId,
          walletNumberType: CardNumberType.AutoGenerated,
        })

        const { prepaidWallet } = response.data

        // Fetch outlet for the outlet wallet minimum top-up amount
        await dispatch(
          fetchOutletDetails({ outletId: prepaidWallet.soldOutletId }),
        )

        return prepaidWallet
      }

      return null
    } catch (error) {
      return rejectWithValue(serializeError(error))
    }
  },
)

export const fetchWalletTransactions = createAppAsyncThunk<
  PrepaidWalletTransactionPaginatedResponse,
  {
    offset?: number
    limit?: number
    tenantId: string
    walletId: string
  }
>(
  'wallets/fetchWalletTransactions',
  async ({ offset, limit, tenantId, walletId }, { rejectWithValue }) => {
    /**
     * Note: The endpoint is not supported filter in:0,1,2.
     * So, we need to use multiple filters as following.
     * filter[type]: [in:0,in:1,in:2] etc.
     */
    const transactionsFilter = new APIFilterBuilder().addFilter(
      'type',
      Object.values(WalletTransactionTypes).map(type => `in:${type}`),
    )

    try {
      const response = await api.card.prepaidWallets.get.walletTransactions({
        limit,
        offset,
        order: 'timestamp:desc',
        tenantId,
        prepaidWalletId: walletId,
        filter: transactionsFilter.build(),
      })

      return response.data
    } catch (error) {
      return rejectWithValue(serializeError(error))
    }
  },
)
