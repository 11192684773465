import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { BodyTextProps } from '@ancon/wildcat-ui/web/orderweb/BodyText'
import { OrderFormat } from '@ancon/wildcat-types'

import BodyText from './BodyText'

type OrderFormatTextProps = BodyTextProps & {
  orderFormat: OrderFormat
}

export default function OrderFormatText({
  orderFormat,
  ...bodyTextProps
}: OrderFormatTextProps) {
  const { t } = useTranslation('common')

  function getOrderFormatText() {
    switch (orderFormat) {
      case OrderFormat.EatIn:
        return t('orderFormat.eatIn')
      case OrderFormat.TakeAway:
        return t('orderFormat.takeAway')
      case OrderFormat.TableOrder:
        return t('orderFormat.tableOrder')
      case OrderFormat.Delivery:
        return t('orderFormat.delivery')
      case OrderFormat.BoxPickup:
        return t('orderFormat.boxPickup')
      default:
        return ''
    }
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <BodyText {...bodyTextProps}>{getOrderFormatText()}</BodyText>
  )
}
