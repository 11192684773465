import { createSelector } from '@reduxjs/toolkit'
import {
  AttendeePreOrderStatus,
  CheckoutStatus,
  OrderFormat,
  PreOrderCheckoutDetails,
  PreOrderStatus,
} from '@ancon/wildcat-types'
import getTotalCheckoutItemsQuantity from '@ancon/wildcat-utils/checkout/getTotalCheckoutItemsQuantity'
import moment from 'moment'
import {
  isGroupPreOrder,
  isPreOrderSharable,
} from '@ancon/wildcat-utils/preOrder'
import orderBy from 'lodash/orderBy'

import { RootState } from '../../../store/types'
import {
  HostPreOrderStartAction,
  NewOrderStep,
  PreOrderMethod,
  PreOrderReducerState,
  PreOrderStartStep,
  PreOrderUserRole,
} from '../types'
import isPreOrderHostUserRole from '../utils/isPreOrderHostUserRole'
import isPreOrderAttendeeUserRole from '../utils/isPreOrderAttendeeUserRole'
import {
  clientContextCurrentUserCompanyIdSelector,
  clientContextCustomerEmailHashSelector,
  clientContextIsAnonymousCustomerSelector,
} from '../../clientContext/store/clientContextSelectors'
import {
  outletsSelectedOutletListItemSelector,
  outletsSelectedRestaurantIdSelector,
} from '../../outlets/store/outletsSelectors'
import getPreOrderOrderFormats from '../utils/getPreOrderOrderFormats'

import customerPreOrdersEntityAdapter from './preOrderEntityAdapters'

function preOrderStateSelector<K extends keyof PreOrderReducerState>(
  state: RootState,
  key: K,
) {
  return state.preOrder[key]
}

// Adapter selectors

export const {
  selectById: preOrderUpcomingOrderByIdSelector,
  selectAll: preOrderAllUpcomingOrdersSelector,
} = customerPreOrdersEntityAdapter.getSelectors<RootState>(
  state => state.preOrder.upcomingOrderList,
)

export const {
  selectById: preOrderCalendarOrderByIdSelector,
  selectAll: preOrderAllCalendarOrdersSelector,
} = customerPreOrdersEntityAdapter.getSelectors<RootState>(
  state => state.preOrder.calendarPreOrderList,
)

// Simple selectors
export const preOrderStartStepSelector = (state: RootState) =>
  preOrderStateSelector(state, 'startStep')

export const preOrderHostInfoModalVisibleSelector = (state: RootState) =>
  preOrderStateSelector(state, 'showPreOrderHostInfoModal')

export const isMobilePreOrderMembersManageStepSelector = (state: RootState) =>
  preOrderStateSelector(state, 'isMobilePreOrderMembersManageStep')

export const preOrderSelectedAttendeeIdSelector = (state: RootState) =>
  preOrderStateSelector(state, 'preOrderSelectedAttendeeId')

export const preOrderCheckoutAddItemPendingSelector = (state: RootState) =>
  preOrderStateSelector(state, 'preOrderAddItemPending')

export const preOrderAttendeeDeletePendingSelector = (state: RootState) =>
  preOrderStateSelector(state, 'preOrderAttendeeDeletePending')

export const preOrderConfirmPendingSelector = (state: RootState) =>
  preOrderStateSelector(state, 'preOrderConfirmPending')

export const preOrderUpdatePendingSelector = (state: RootState) =>
  preOrderStateSelector(state, 'preOrderUpdatePending')

export const preOrderValidatePendingSelector = (state: RootState) =>
  preOrderStateSelector(state, 'preOrderValidatePending')

export const preOrderAttendeeRemoveModalVisibleSelector = (state: RootState) =>
  preOrderStateSelector(state, 'isPreOrderAttendeeRemoveModalVisible')

export const preOrderAttendeeConfirmPendingModalVisibleSelector = (
  state: RootState,
) => preOrderStateSelector(state, 'isAttendeeConfirmPendingModalVisible')

export const preOrderAttendanceConfirmationModalVisibleSelector = (
  state: RootState,
) => preOrderStateSelector(state, 'isAttendanceConfirmationModalVisible')

export const preOrderAttendeeEmptyItemsWarningModalVisibleSelector = (
  state: RootState,
) => preOrderStateSelector(state, 'isAttendeeEmptyItemsWarningModalVisible')

export const preOrderLeaveModalVisibleSelector = (state: RootState) =>
  preOrderStateSelector(state, 'isPreOrderLeaveModalVisible')

export const preOrderExistWarningModalVisibleSelector = (state: RootState) =>
  preOrderStateSelector(state, 'isPreOrderExistWarningModalVisible')

export const preOrderCalendarSelectedDateSelector = (state: RootState) =>
  preOrderStateSelector(state, 'orderCalendarSelectedDate') || undefined

export const preOrderCalendarSelectedMonthSelector = (state: RootState) =>
  preOrderStateSelector(state, 'orderCalendarSelectedMonth') || undefined

export const preOrderNewOrderStepSelector = (state: RootState) =>
  preOrderStateSelector(state, 'newOrderStep')

export const groupPreOrderCreatePendingSelector = (state: RootState) =>
  preOrderStateSelector(state, 'newGroupOrderCreatePending')

export const preOrderNewOrderStateSelector = (state: RootState) =>
  preOrderStateSelector(state, 'newOrder')

export const preOrderNewOrderModalVisibleSelector = (state: RootState) =>
  preOrderStateSelector(state, 'isNewOrderModalVisible')

export const preOrderNewOrderEditModeStepSelector = (state: RootState) =>
  preOrderStateSelector(state, 'newOrderEditModeStep')

export const preOrderUpcomingOrdersFetchPendingSelector = (state: RootState) =>
  preOrderStateSelector(state, 'upcomingOrderList').isFetchPending

const preOrderUpcomingOrderListPaginationMetaSelector = (state: RootState) =>
  preOrderStateSelector(state, 'upcomingOrderList').meta

export const preOrderUpcomingOrderListOffsetSelector = (state: RootState) =>
  preOrderUpcomingOrderListPaginationMetaSelector(state).offset

export const preOrderUpcomingOrderListTotalCountSelector = (state: RootState) =>
  preOrderUpcomingOrderListPaginationMetaSelector(state).count

export const preOrderHasMoreUpcomingOrdersSelector = (state: RootState) =>
  preOrderStateSelector(state, 'upcomingOrderList').hasMore

export const preOrderCalendarOrdersFetchPendingSelector = (state: RootState) =>
  preOrderStateSelector(state, 'calendarPreOrderList').isFetchPending

export const preOrderCreatedGroupOrderIdSelector = (state: RootState) =>
  preOrderStateSelector(state, 'createdGroupOrderId')

export const preOrderSelectedPreOrderIdSelector = (state: RootState) =>
  preOrderStateSelector(state, 'selectedPreOrderId')

export const preOrderSelectedListItemSelector = (state: RootState) =>
  preOrderStateSelector(state, 'selectedPreOrderListItem')

export const preOrderSelectedOrderDetailsSelector = (state: RootState) =>
  preOrderStateSelector(state, 'selectedPreOrderDetails')

export const preOrderSelectedOrderFetchPendingSelector = (state: RootState) =>
  preOrderStateSelector(state, 'selectedPreOrderFetchPending')

export const preOrderNewOrderDateSelector = (state: RootState) =>
  preOrderNewOrderStateSelector(state).orderDate

export const preOrderNewOrderTimeSelector = (state: RootState) =>
  preOrderNewOrderStateSelector(state).orderTime

export const preOrderNewOrderOrderFormatSelector = (state: RootState) =>
  preOrderNewOrderStateSelector(state).orderFormat

export const preOrderNewOrderSelectedOrderMethodSelector = (state: RootState) =>
  preOrderNewOrderStateSelector(state).orderMethod

export const preOrderNewGroupOrderNameSelector = (state: RootState) =>
  preOrderNewOrderStateSelector(state).groupOrderName

export const preOrderNewGroupOrderSizeSelector = (state: RootState) =>
  preOrderNewOrderStateSelector(state).groupOrderSize

export const selectedPreOrderOrderFormatSelector = (state: RootState) =>
  preOrderSelectedOrderDetailsSelector(state)?.orderFormat

export const selectedPreOrderServiceTimeSelector = (state: RootState) =>
  preOrderSelectedOrderDetailsSelector(state)?.serviceTime

export const selectedPreOrderOutletSelector = (state: RootState) =>
  preOrderSelectedOrderDetailsSelector(state)?.outlet

export const selectedPreOrderAttendeesSelector = (state: RootState) =>
  (preOrderSelectedOrderDetailsSelector(state) as PreOrderCheckoutDetails)
    .attendees ?? []

export const selectedPreOrderItemsSelector = (state: RootState) =>
  preOrderSelectedOrderDetailsSelector(state)?.items ?? []

export const selectedPreOrderMembersAttendanceSelector = (state: RootState) =>
  selectedPreOrderAttendeesSelector(state).length

export const selectedPreOrderOutletNameSelector = (state: RootState) =>
  selectedPreOrderOutletSelector(state)?.name

export const selectedPreOrderOutletIdSelector = (state: RootState) =>
  selectedPreOrderOutletSelector(state)?.id

export const selectedPreOrderOrderTypeSelector = (state: RootState) =>
  preOrderSelectedOrderDetailsSelector(state)?.orderType

export const selectedPreOrderStatusSelector = (state: RootState) =>
  (preOrderSelectedOrderDetailsSelector(state) as PreOrderCheckoutDetails)
    ?.preOrderStatus

export const preOrderSelectedListItemOrderNameSelector = (state: RootState) =>
  preOrderSelectedListItemSelector(state)?.preOrderName

export const preOrderSelectedListItemTicketNumberSelector = (
  state: RootState,
) => preOrderSelectedListItemSelector(state)?.ticketNumber

export const preOrderSelectedListItemOutletIdSelector = (state: RootState) =>
  preOrderSelectedListItemSelector(state)?.outlet.id

export const preOrderSelectedListItemHostAttendeeIdSelector = (
  state: RootState,
) => preOrderSelectedListItemSelector(state)?.hostAttendeeId

export const preOrderSelectedListItemOrderTypeSelector = (state: RootState) =>
  preOrderSelectedListItemSelector(state)?.orderType

export const preOrderSelectedListItemIsSharableSelector = createSelector(
  preOrderSelectedListItemSelector,
  preOrderListItem => isPreOrderSharable(preOrderListItem!),
)

// Pre-order link selectors
export const preOrderLinkSelector = (state: RootState) =>
  preOrderStateSelector(state, 'preOrderLink')

export const preOrderLinkIsActivatedSelector = (state: RootState) =>
  !!preOrderLinkSelector(state)?.isActivated

export const preOrderLinkOutletIdSelector = (state: RootState) =>
  preOrderLinkSelector(state)?.outletId

export const preOrderLinkOutletURLSlugSelector = (state: RootState) =>
  preOrderLinkSelector(state)?.urlSlug

export const preOrderLinkCheckoutIdSelector = (state: RootState) =>
  preOrderLinkSelector(state)?.checkoutId

export const preOrderLinkOrderFormatSelector = (state: RootState) =>
  preOrderLinkSelector(state)?.orderFormat

export const preOrderHostEmailHashSelector = (state: RootState) =>
  preOrderLinkSelector(state)?.hostEmailHash

export const preOrderLinkAttendeesSelector = (state: RootState) =>
  preOrderLinkSelector(state)?.preOrderAttendees ?? []

export const preOrderLinkPreOrderStatusSelector = (state: RootState) =>
  preOrderLinkSelector(state)?.preOrderStatus

// Pre-order checkout selectors
export const preOrderCheckoutSelector = (state: RootState) =>
  preOrderStateSelector(state, 'preOrderCheckout')

export const preOrderCheckoutFetchPendingSelector = (state: RootState) =>
  preOrderStateSelector(state, 'preOrderCheckoutFetchPending')

export const isPreOrderCheckoutSelector = (state: RootState) =>
  !!preOrderCheckoutSelector(state)

export const preOrderCheckoutIdSelector = (state: RootState) =>
  preOrderCheckoutSelector(state)?.id

export const preOrderCheckoutOutletSelector = (state: RootState) =>
  preOrderCheckoutSelector(state)?.outlet

export const preOrderCheckoutOrderTypeSelector = (state: RootState) =>
  preOrderCheckoutSelector(state)?.orderType

export const preOrderCheckoutOutletIdSelector = (state: RootState) =>
  preOrderCheckoutOutletSelector(state)?.id

export const preOrderCheckoutOutletNameSelector = (state: RootState) =>
  preOrderCheckoutOutletSelector(state)?.name

export const preOrderCheckoutAttendeeSelector = (state: RootState) =>
  preOrderCheckoutSelector(state)?.attendee

export const preOrderCheckoutAttendeesSelector = (state: RootState) =>
  preOrderCheckoutSelector(state)?.attendees ?? []

export const preOrderCheckoutHostSelector = (state: RootState) =>
  preOrderCheckoutSelector(state)?.host

export const preOrderCheckoutCurrencySelector = (state: RootState) =>
  preOrderCheckoutSelector(state)?.totalAmount?.currency

export const preOrderCheckoutNameSelector = (state: RootState) =>
  preOrderCheckoutSelector(state)?.preOrderName

export const preOrderApproxNoOfAttendeesSelector = (state: RootState) =>
  preOrderCheckoutSelector(state)?.noOfAttendees || 0

export const preOrderMembersAttendanceSelector = (state: RootState) =>
  preOrderCheckoutAttendeesSelector(state).length

export const preOrderCheckoutStatusSelector = (state: RootState) =>
  preOrderCheckoutSelector(state)?.checkoutStatus

export const preOrderCheckoutOrderFormatSelector = (state: RootState) =>
  preOrderCheckoutSelector(state)?.orderFormat

export const preOrderCheckoutDeliveryAddressSelector = (state: RootState) =>
  preOrderCheckoutSelector(state)?.delivery?.address

export const preOrderCheckoutOrderInstructionsSelector = (state: RootState) =>
  preOrderCheckoutSelector(state)?.instructions

export const preOrderCheckoutItemsSelector = (state: RootState) =>
  preOrderCheckoutSelector(state)?.items ?? []

export const preOrderCheckoutDiscountSelector = (state: RootState) =>
  preOrderCheckoutSelector(state)?.discount

export const preOrderCheckoutTotalDiscountAmountSelector = (state: RootState) =>
  preOrderCheckoutSelector(state)?.subTotalDiscountInclTax

export const preOrderCheckoutCompanySelector = (state: RootState) =>
  preOrderCheckoutSelector(state)?.company

export const preOrderCheckoutCompanyIdSelector = (state: RootState) =>
  preOrderCheckoutCompanySelector(state)?.id

// Pre-order signed-in user (host/attendee) selectors
export const preOrderUserSelector = (state: RootState) =>
  preOrderStateSelector(state, 'preOrderUser')

export const preOrderUserIdSelector = (state: RootState) =>
  preOrderUserSelector(state)?.id

export const preOrderUserEmailSelector = (state: RootState) =>
  preOrderUserSelector(state)?.email

export const preOrderUserIsHostSelector = (state: RootState) =>
  preOrderUserSelector(state)?.isHost === true

export const preOrderUserIsAttendeeSelector = (state: RootState) =>
  preOrderUserSelector(state)?.isHost === false

export const preOrderUserNameSelector = (state: RootState) =>
  preOrderUserSelector(state)?.name

// Pre-order settings selectors
export const preOrderSettingsSelector = (state: RootState) =>
  preOrderStateSelector(state, 'preOrderSettings')

export const preOrderSettingsFetchPendingSelector = (state: RootState) =>
  preOrderStateSelector(state, 'preOrderSettingsFetchPending')

// Combined selectors

export const preOrderLinkIsGroupOrderSelector = createSelector(
  preOrderLinkSelector,
  preOrderLink => isGroupPreOrder(preOrderLink!),
)

export const preOrderCheckoutAttendeeByIdSelector = (
  state: RootState,
  attendeeId?: string | null,
) => {
  const preOrderCheckout = preOrderCheckoutSelector(state)

  if (!attendeeId || !preOrderCheckout) {
    return undefined
  }

  const { attendee, attendees } = preOrderCheckout

  if (attendee && attendee.id === attendeeId) {
    return attendee
  }

  return (attendees ?? []).find(a => a.id === attendeeId)
}

export const preOrderCheckoutUserAttendeeSelector = (state: RootState) => {
  const preOrderUserId = preOrderUserIdSelector(state)
  const preOrderAttendee = preOrderCheckoutAttendeeByIdSelector(
    state,
    preOrderUserId,
  )
  return preOrderAttendee
}

export const preOrderCheckoutUserItemsSelector = createSelector(
  preOrderCheckoutUserAttendeeSelector,
  attendee => attendee?.items ?? [],
)

export const preOrderCheckoutTotalItemCountSelector = createSelector(
  preOrderCheckoutUserItemsSelector,
  getTotalCheckoutItemsQuantity,
)

export const preOrderAttendeeItemsByIdSelector = createSelector(
  preOrderCheckoutAttendeeByIdSelector,
  attendee => attendee?.items ?? [],
)

export const preOrderSelectedAttendeeNameSelector = (state: RootState) => {
  const selectedAttendeeId = preOrderSelectedAttendeeIdSelector(state)
  const attendee = preOrderCheckoutAttendeeByIdSelector(
    state,
    selectedAttendeeId,
  )
  return attendee?.name ?? null
}

export const currentCustomerPreOrderUserRoleSelector = createSelector(
  [clientContextCustomerEmailHashSelector, preOrderHostEmailHashSelector],
  (customerEmailHash, preOrderHostEmailHash) => {
    if (customerEmailHash && preOrderHostEmailHash) {
      const isHost = customerEmailHash === preOrderHostEmailHash
      return isHost ? PreOrderUserRole.Host : PreOrderUserRole.Attendee
    }

    return PreOrderUserRole.Unknown
  },
)

export const currentCustomerIsPreOrderHostSelector = createSelector(
  currentCustomerPreOrderUserRoleSelector,
  isPreOrderHostUserRole,
)

export const currentCustomerIsPreOrderAttendeeSelector = createSelector(
  currentCustomerPreOrderUserRoleSelector,
  isPreOrderAttendeeUserRole,
)

export const currentCustomerIsUnknownPreOrderUserSelector = createSelector(
  currentCustomerPreOrderUserRoleSelector,
  userRole => userRole === PreOrderUserRole.Unknown,
)

export const hostPreOrderStartActionSelector = createSelector(
  [
    preOrderStartStepSelector,
    preOrderLinkIsActivatedSelector,
    preOrderLinkIsGroupOrderSelector,
    currentCustomerIsUnknownPreOrderUserSelector,
  ],
  (step, isActivated, isGroupOrder, isUnknownUser) => {
    switch (step) {
      case PreOrderStartStep.Second: {
        return isActivated
          ? HostPreOrderStartAction.StartPreOrder
          : HostPreOrderStartAction.Activate
      }

      case PreOrderStartStep.First:
      default:
        return !isGroupOrder && (!isActivated || isUnknownUser)
          ? HostPreOrderStartAction.EnterPin
          : HostPreOrderStartAction.StartPreOrder
    }
  },
)

export const preOrderHasUnconfirmedAttendeeSelector = createSelector(
  preOrderCheckoutAttendeesSelector,
  attendees =>
    attendees.some(
      attendee =>
        !attendee.isHost && attendee.status === AttendeePreOrderStatus.Pending,
    ),
)

export const preOrderHasMembersAttendanceMismatchSelector = createSelector(
  [preOrderApproxNoOfAttendeesSelector, preOrderMembersAttendanceSelector],
  (ApproxNoOfAttendees, attendanceOfMembers) =>
    ApproxNoOfAttendees !== attendanceOfMembers,
)

export const preOrderIsWaitingForPaymentSelector = createSelector(
  preOrderCheckoutStatusSelector,
  checkoutStatus => checkoutStatus === CheckoutStatus.Received,
)

export const preOrderCheckoutTotalItemQtySelector = createSelector(
  preOrderCheckoutItemsSelector,
  items => getTotalCheckoutItemsQuantity(items),
)

export const preOrderCheckoutIsValidCheckoutSelector = createSelector(
  [
    preOrderCheckoutOrderFormatSelector,
    preOrderCheckoutDeliveryAddressSelector,
  ],
  (orderFormat, deliveryAddress) => {
    switch (orderFormat) {
      case OrderFormat.Delivery:
        return !!deliveryAddress
      case OrderFormat.EatIn:
      case OrderFormat.TakeAway:
        return true
      default:
        return false
    }
  },
)

export const preOrderIsNewOrderModeSelector = createSelector(
  preOrderNewOrderStepSelector,
  newOrderStep => newOrderStep > NewOrderStep.None,
)

export const preOrderIsNewOrderEditModeSelector = createSelector(
  preOrderNewOrderEditModeStepSelector,
  newOrderEditStep => newOrderEditStep > NewOrderStep.None,
)

export const preOrderCalendarOrdersForSelectedDateSelector = createSelector(
  [preOrderCalendarSelectedDateSelector, preOrderAllCalendarOrdersSelector],
  (selectedDate, orders) =>
    orders.filter(order =>
      moment(selectedDate).isSame(order.serviceTime.time, 'day'),
    ),
)

export const preOrderCalendarOrderDatesSelector = createSelector(
  preOrderAllCalendarOrdersSelector,
  orders =>
    orders.reduce<string[]>((acc, order) => {
      const orderDate = moment(order.serviceTime.time).format()
      if (!acc.includes(orderDate)) {
        acc.push(orderDate)
      }
      return acc
    }, []),
)

export const preOrderCalendarScheduledOrdersLoadingSelector = createSelector(
  [
    preOrderCalendarOrdersFetchPendingSelector,
    preOrderCalendarSelectedDateSelector,
    preOrderCalendarSelectedMonthSelector,
  ],
  (isOrdersFetchPending, selectedDate, selectedMonth) =>
    isOrdersFetchPending &&
    // If selected date is not in the selected month, don't show loading
    moment(selectedDate).isSame(selectedMonth, 'month'),
)

export const preOrderNewOrderModalStepSelector = createSelector(
  [preOrderNewOrderStepSelector, preOrderNewOrderEditModeStepSelector],
  (newOrderStep, newOrderEditModeStep) =>
    newOrderStep === NewOrderStep.Summary ? newOrderEditModeStep : newOrderStep,
)

export const selectedPreOrderOrderMethodSelector = createSelector(
  preOrderSelectedOrderDetailsSelector,
  preOrder =>
    isPreOrderSharable(preOrder!)
      ? PreOrderMethod.Group
      : PreOrderMethod.Individual,
)

export const selectedPreOrderIsGroupOrderSelector = createSelector(
  preOrderSelectedOrderDetailsSelector,
  preOrder => isGroupPreOrder(preOrder as PreOrderCheckoutDetails),
)

export const selectedPreOrderIsStartedSelector = createSelector(
  selectedPreOrderStatusSelector,
  preOrderStatus => preOrderStatus >= PreOrderStatus.Started,
)

export const preOrderCurrentCheckoutPendingSelector = createSelector(
  [
    preOrderCheckoutFetchPendingSelector,
    preOrderCheckoutAddItemPendingSelector,
    preOrderUpdatePendingSelector,
    preOrderValidatePendingSelector,
  ],
  (
    preOrderFetchPending,
    preOrderAddItemPending,
    preOrderUpdatePending,
    preOrderValidatePending,
  ) =>
    preOrderFetchPending ||
    preOrderAddItemPending ||
    preOrderUpdatePending ||
    preOrderValidatePending,
)

export const preOrderSelectedRestaurantMaxAttendeeLimit = createSelector(
  [preOrderSettingsSelector, outletsSelectedRestaurantIdSelector],
  (preOrderSettings, selectedRestaurantId) => {
    if (preOrderSettings) {
      const { maximumAttendeeCount, outletSettings } = preOrderSettings
      const overriddenSettings = outletSettings.find(
        setting => setting.outletId === selectedRestaurantId,
      )
      return (
        overriddenSettings?.maximumAttendeeCount ?? maximumAttendeeCount ?? null
      )
    }

    return null
  },
)

export const preOrderHostHasAddedItemsSelector = createSelector(
  preOrderCheckoutAttendeesSelector,
  attendees => {
    const host = attendees.find(attendee => attendee.isHost)
    return !!host?.items.length
  },
)

export const preOrderIsAnonymousAttendeeSelector = createSelector(
  [clientContextIsAnonymousCustomerSelector, preOrderUserIsAttendeeSelector],
  (isAnonymousCustomer, isAttendee) => isAnonymousCustomer && isAttendee,
)

export const preOrderHasAnyConfirmedAttendeeWithoutItemsSelector =
  createSelector(preOrderCheckoutAttendeesSelector, attendees =>
    attendees.some(
      attendee =>
        !attendee.isHost &&
        attendee.status === AttendeePreOrderStatus.Confirmed &&
        !attendee.items.length,
    ),
  )

export const preOrderCheckoutSortedAttendeesSelector = createSelector(
  preOrderCheckoutAttendeesSelector,
  attendees =>
    orderBy(
      attendees,
      [attendee => (attendee.isHost ? 1 : 0), 'name'],
      ['desc', 'asc'],
    ),
)

export const isPreOrderCheckoutCompanySameAsUserProfileSelector =
  createSelector(
    [
      clientContextCurrentUserCompanyIdSelector,
      preOrderCheckoutCompanyIdSelector,
    ],
    (currentCompanyUserId, checkoutCompanyId) =>
      currentCompanyUserId === (checkoutCompanyId || null),
  )

export const preOrderSupportedOrderFormatsSelector = createSelector(
  outletsSelectedOutletListItemSelector,
  getPreOrderOrderFormats,
)
