import { OrderFormat } from '@ancon/wildcat-types'

const DisplayableOrderFormats: Array<OrderFormat> = [
  OrderFormat.EatIn,
  OrderFormat.TakeAway,
  OrderFormat.TableOrder,
  OrderFormat.Delivery,
  OrderFormat.BoxPickup,
]

export default function isOrderFormatDisplayable(orderFormat?: OrderFormat) {
  return orderFormat && DisplayableOrderFormats.includes(orderFormat)
}
