import { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'

import InteractiveDiv from '../../../layout/components/InteractiveDiv'

import DefaultDropdownButton, { DropdownButtonProps } from './DropdownButton'
import styles from './Dropdown.module.scss'

export type DropdownOption = {
  label: string
  value: string
  disabled?: boolean
}

type DropdownProps = {
  label?: string
  labelClassName?: string
  popupPlacement?: 'top-start' | 'top-end'
  CustomDropdownButton?: React.FC<DropdownButtonProps>
  options: DropdownOption[]
  onClickOption?: (value: string) => void
  buttonClassName?: string
  popupClassName?: string
  value?: DropdownOption
}

export default function Dropdown({
  label,
  labelClassName,
  popupPlacement = 'top-start',
  CustomDropdownButton = DefaultDropdownButton,
  options,
  onClickOption,
  buttonClassName,
  popupClassName,
  value: selectedOption,
}: DropdownProps) {
  const [isOpen, setIsOpen] = useState(false)

  const dropdownRef = useRef<HTMLDivElement>(null)

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  function handleOptionClick(value: string) {
    onClickOption?.(value)
    setIsOpen(false)
  }

  // Listen outside press events
  useEffect(() => {
    function handleMouseDown(e: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target as Node)
      ) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleMouseDown)

    return () => {
      document.removeEventListener('mousedown', handleMouseDown)
    }
  }, [])

  return (
    <div className={styles.container} ref={dropdownRef}>
      <CustomDropdownButton
        className={clsx(styles.toggle, buttonClassName)}
        isOpen={isOpen}
        label={label}
        labelClassName={labelClassName}
        onToggle={toggleDropdown}
      />
      {isOpen && (
        <div
          className={clsx(styles.popup, popupClassName, {
            [styles.topStartPlacement]: popupPlacement === 'top-start',
            [styles.topEndPlacement]: popupPlacement === 'top-end',
          })}
        >
          {options.map(({ label: optionLabel, value, disabled }) => (
            <InteractiveDiv
              className={clsx(styles.dropdownItem, {
                [styles.selected]: selectedOption?.value === value,
              })}
              key={value}
              onClick={() => handleOptionClick(value)}
              disabled={disabled}
            >
              {optionLabel}
            </InteractiveDiv>
          ))}
        </div>
      )}
    </div>
  )
}
