import { APIParams } from '@ancon/wildcat-types'

function createUrlParams(params?: APIParams) {
  return (
    params &&
    Object.entries(params)
      .map(([key, val]) => `${key}=${val}`)
      .join('&')
  )
}

export default function getImageUrl(
  imageId: string,
  params?: APIParams,
  version: number = 1,
) {
  const queryParams = createUrlParams(params)

  return `${
    process.env.NEXT_PUBLIC_MEDIA_API_URL
  }/v${version}/media/${imageId}${queryParams ? `?${queryParams}` : ''}`
}
