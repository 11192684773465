import React, { HTMLInputTypeAttribute } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useFormState } from 'react-hook-form'

import PasswordVisibilityIcon from '../components/PasswordVisibilityIcon'
import FormInput from '../../app/components/FormInput'
import userConstraints from '../../app/constraints/userConstraints'
import useToggleState from '../../app/hooks/useToggleState'

type LoginFormInputsProps = {
  readonlyEmail?: boolean
  inputContainerClassName?: string
  showErrorOnTouched?: boolean
}

export default function LoginFormInputs({
  readonlyEmail = false,
  inputContainerClassName,
  showErrorOnTouched,
}: LoginFormInputsProps) {
  const { t } = useTranslation('common')
  const { isSubmitting } = useFormState()
  const [showPassword, toggleShowPassword] = useToggleState(false)

  function getPasswordInputType(): HTMLInputTypeAttribute {
    return showPassword ? 'text' : 'password'
  }

  return (
    <>
      <FormInput
        fieldName="email"
        autoComplete="off"
        autoCapitalize="none"
        placeholder={t('placeholders.email')}
        label={t('user.email')}
        type="email"
        rules={{
          required: t('validations.required'),
          pattern: {
            message: t('validations.invalidEmail'),
            value: userConstraints.pattern.email,
          },
          maxLength: {
            value: userConstraints.max.email,
            message: t('validations.maxLength', {
              limit: userConstraints.max.email,
            }),
          },
        }}
        readOnly={readonlyEmail || isSubmitting}
        containerClassName={inputContainerClassName}
        showErrorOnTouched={showErrorOnTouched}
        required
      />
      <FormInput
        fieldName="password"
        autoComplete="off"
        autoCapitalize="none"
        placeholder={t('placeholders.password')}
        label={t('user.password')}
        type={getPasswordInputType()}
        rules={{
          required: t('validations.required'),
          minLength: {
            value: userConstraints.min.password,
            message: t('validations.minLength', {
              limit: userConstraints.min.password,
            }),
          },
          pattern: {
            message: t('validations.weakPassword'),
            value: userConstraints.pattern.password,
          },
        }}
        rightAccessory={<PasswordVisibilityIcon visible={showPassword} />}
        rightAccessoryOnClick={toggleShowPassword}
        readOnly={isSubmitting}
        containerClassName={inputContainerClassName}
        showErrorOnTouched={showErrorOnTouched}
        required
      />
    </>
  )
}
