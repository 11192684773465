import ShoppingBagOutlineIcon from '@ancon/wildcat-ui/shared/icons/shopping-bag-outline.svg'
import useTranslation from 'next-translate/useTranslation'
import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'

import Button from '../../../../app/components/Button'
import useAppDispatch from '../../../../../store/hooks/useAppDispatch'
import useDeviceSize from '../../../../app/hooks/useDeviceSize'
import { appSetIsCartDrawerVisible } from '../../../../app/store/appSlice'
import useAppSelector from '../../../../../store/hooks/useAppSelector'
import { currentCheckoutTotalItemQtySelector } from '../../../../checkout/store/checkoutSelectors'
import { validateCheckout } from '../../../../checkout/store/checkoutThunks'
import useCartDrawerOpen from '../../../../checkout/hooks/useCartDrawerOpen'
import usePrevious from '../../../../app/hooks/usePrevious'
import localStorageUtils from '../../../../app/utils/localStorageUtils'
import { LocalStorageCartMeta } from '../../../../checkout/types'
import { validatePreOrderCheckout } from '../../../../preOrder/store/preOrderThunks'
import { isPreOrderCheckoutSelector } from '../../../../preOrder/store/preOrderSelectors'

import styles from './CartButton.module.scss'

export default function CartButton() {
  const { t } = useTranslation('common')

  const [cartIconAnimate, setCartIconAnimate] = useState(false)
  const dispatch = useAppDispatch()
  const { isLargeDesktop } = useDeviceSize()
  const timeOutRef = useRef<NodeJS.Timeout>()

  const isPreOrderCheckout = useAppSelector(isPreOrderCheckoutSelector)

  const isCartOpen = useCartDrawerOpen()
  const cartItemsCount = useAppSelector(currentCheckoutTotalItemQtySelector)
  const prevCartItemsCount = usePrevious(cartItemsCount)

  function handleCartToggleClick() {
    if (!isPreOrderCheckout && isLargeDesktop) return
    dispatch(appSetIsCartDrawerVisible(!isCartOpen))
  }

  // Initial checkout validation
  useEffect(() => {
    const checkout = localStorageUtils.getItem<LocalStorageCartMeta>('cartMeta')

    if (checkout) {
      if (checkout.isPreOrderCheckout) {
        dispatch(validatePreOrderCheckout({}))
      } else {
        dispatch(validateCheckout({}))
      }
    }
  }, [dispatch])

  // Animate cart icon when adding item to cart
  useEffect(() => {
    const isItemAddition = cartItemsCount > (prevCartItemsCount || 0)

    if (isItemAddition) {
      setCartIconAnimate(true)
      timeOutRef.current = setTimeout(() => {
        setCartIconAnimate(false)
      }, 2000)
    }
  }, [cartItemsCount, prevCartItemsCount])

  // Clear timeout on unmount
  useEffect(
    () => () => {
      clearTimeout(timeOutRef.current)
    },
    [],
  )

  return (
    <Button
      size="large"
      variant={isCartOpen ? 'tertiary' : 'secondary'}
      onClick={handleCartToggleClick}
      className={clsx(styles.cartButton, {
        [styles.cartOpen]: isCartOpen,
      })}
    >
      <ShoppingBagOutlineIcon
        className={clsx({
          [styles.wobbleBottom]: cartIconAnimate,
        })}
      />
      <span>{t('components.navBar.cart')}</span>
      <span>&middot;</span>
      <span>{cartItemsCount || 0}</span>
    </Button>
  )
}
