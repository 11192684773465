import React from 'react'
import CloseIcon from '@ancon/wildcat-ui/shared/icons/close.svg'
import OrderTypeIcon from '@ancon/wildcat-ui/web/orderweb/OrderTypeIcon'
import {
  OrderFormat,
  ServiceTime,
  ServiceTimeKindType,
} from '@ancon/wildcat-types'
import moment from 'moment'
import useTranslation from 'next-translate/useTranslation'

import HeadingText from '../../../app/components/HeadingText'
import BodyText from '../../../app/components/BodyText'
import Button from '../../../app/components/Button'
import useAppDispatch from '../../../../store/hooks/useAppDispatch'
import { appSetIsCartDrawerVisible } from '../../../app/store/appSlice'
import Spinner from '../../../app/components/Spinner'
import OrderFormatText from '../../../app/components/OrderFormatText'
import PreOrderTag from '../../../preOrder/components/PreOrderTag'
import PreOrderAttendeesCountTag from '../../../preOrder/components/PreOrderAttendeesCountTag'
import isOrderFormatDisplayable from '../../../app/utils/isOrderFormatDisplayable'

import styles from './CartHeader.module.scss'

interface CartHeaderProps {
  title: string
  outletName: string
  orderFormat?: OrderFormat
  loading?: boolean
  serviceTime?: ServiceTime
  isPreOrder?: boolean
  additionalActions?: React.ReactNode
}

export default function CartHeader({
  title,
  outletName,
  orderFormat,
  serviceTime,
  loading = false,
  isPreOrder = false,
  additionalActions,
}: CartHeaderProps) {
  const { t } = useTranslation('common')

  const dispatch = useAppDispatch()

  function handleDrawerCloseClick() {
    dispatch(appSetIsCartDrawerVisible(false))
  }

  const showServiceTime =
    serviceTime && serviceTime.kind === ServiceTimeKindType.AtSpecifiedTime

  return (
    <div className={styles.headerContainer}>
      <div className={styles.headerInnerContainer}>
        <div className={styles.headerTop}>
          <div className={styles.headerTopTitle}>
            <HeadingText as="h2">{title}</HeadingText>
            {loading && (
              <div className={styles.headerTopLoader}>
                <Spinner size="medium" />
              </div>
            )}
          </div>
          <div className={styles.headerTopActions}>
            {additionalActions}
            <Button
              className={styles.headerCloseButton}
              variant="secondary"
              onClick={handleDrawerCloseClick}
            >
              <CloseIcon />
            </Button>
          </div>
        </div>
        <div className={styles.headerInformation}>
          <BodyText color="body-1">{outletName}</BodyText>
          {showServiceTime && (
            <div className={styles.serviceTime}>
              <BodyText color="body-1">
                {moment(serviceTime.time).calendar({
                  sameDay: `[${t('today')}]`,
                  nextDay: `[${t('tomorrow')}]`,
                  nextWeek: 'dddd',
                  sameElse: 'LL',
                })}
              </BodyText>
              <BodyText className={styles.middot} fontSize="2.4rem">
                &middot;
              </BodyText>
              <BodyText color="body-1">
                {moment(serviceTime.time).format('LT')}
              </BodyText>
            </div>
          )}
          <div className={styles.headerInformationTags}>
            {isOrderFormatDisplayable(orderFormat) && (
              <div className={styles.orderFormat}>
                <OrderTypeIcon orderFormat={orderFormat!} />
                <OrderFormatText as="span" orderFormat={orderFormat!} />
              </div>
            )}
            {isPreOrder && (
              <>
                <PreOrderTag />
                <PreOrderAttendeesCountTag />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
