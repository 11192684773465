import { OrderFormat, OutletListItem } from '@ancon/wildcat-types'
import getOutletOrderFormats from '@ancon/wildcat-utils/outlet/getOutletOrderFormats'

export default function getPreOrderOrderFormats(outlet: OutletListItem | null) {
  if (outlet) {
    const outletOrderFormats = getOutletOrderFormats(outlet)

    /**
     * Signed-in companies usually order for take-away and rarely for eat-in.
     * So, we move `TakeAway` to the first position.
     */
    const takeAwayIdx = outletOrderFormats.findIndex(
      orderFormat => orderFormat === OrderFormat.TakeAway,
    )
    if (takeAwayIdx > 0) {
      outletOrderFormats.splice(takeAwayIdx, 1)
      outletOrderFormats.unshift(OrderFormat.TakeAway)
    }

    return outletOrderFormats.filter(
      // `TableOrder & BoxPickup` is not supported for pre-order
      orderFormat =>
        orderFormat !== OrderFormat.TableOrder &&
        orderFormat !== OrderFormat.BoxPickup,
    )
  }

  return []
}
